<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <b-col cols="12" xl="12" md="12">
        <b-card no-body class="invoice-preview-card">
          <iframe width="100%" height="600" :src="pdfUrl"></iframe>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
  },
  computed:{
    pdfUrl(){
      return process.env.VUE_APP_API_URL + 'api/print/ticket/' + router.currentRoute.params.id
    },
    isCashier() {
      return this.$store.getters['user/userData'].roles.find(role => role.name == 'cashier')
    },
  },
  mounted() {
    setTimeout(() => {
      if(this.isCashier){
        this.$router.push({ name: 'apps-e-commerce-checkout' })
      } else {
        this.$router.push({ name: 'apps-e-commerce-shop' })
      }
    }, 10000)
  }
}
</script>